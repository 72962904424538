import { Review } from "../../../pages";
import userRouteMap from "../../../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: userRouteMap.BOOKING_REVIEW.path,
      name: "Booking And Review",
      key: userRouteMap.BOOKING_REVIEW.path,
      private: true,
      belongsToSidebar: true,
      element: <Review />,
    },
  ];
}
