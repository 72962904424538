import { Payment } from "../../../pages";
import userRouteMap from "../../../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: userRouteMap.PAYMENT.path,
      name: "PackageSelectionOption",
      key: userRouteMap.PAYMENT.path,
      private: true,
      belongsToSidebar: true,
      element: <Payment />,
    },
  ];
}
