import React from "react";

export const HomePage = React.lazy(() => import("./HomePage/index"));
export const HotelAndCabs = React.lazy(() => import("./HotelAndCabs/index"));
export const FlightList = React.lazy(() =>
  import("./FlightListing/FlightListing")
);
export const FlightReturn = React.lazy(() =>
  import("./FlightListingReturn/index")
);
export const Packages = React.lazy(() => import("./Packages/Index"));
export const PackageSelectionOption = React.lazy(() =>
  import("./PackageSelectionOption/Index")
);
export const Payment = React.lazy(() => import("./PaymentOption/index"));
export const LoginRegistration = React.lazy(() =>
  import("./LoginRegistration")
);

export const Review = React.lazy(() => import("./Review/Review"));
export const Account = React.lazy(() => import("./Account/index"));

// export const Packages = React.lazy(() => import("./Account/index"));
