import { FlightReturn } from "../../../pages";
import userRouteMap from "../../../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: userRouteMap.FLIGHT_RETURN.path,
      name: "Filght Return",
      key: userRouteMap.FLIGHT_RETURN.path,
      private: true,
      belongsToSidebar: true,
      element: <FlightReturn />,
    },
  ];
}
