import { HomePage, HotelAndCabs } from "../../../pages";
import userRouteMap from "../../../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: userRouteMap.HOTEL_CABS.path,
      name: "Hotel And Cabs",
      key: userRouteMap.HOTEL_CABS.path,
      private: true,
      belongsToSidebar: true,
      element: <HotelAndCabs />,
    },
  ];
}
