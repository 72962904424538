import { LoginRegistration } from "../../../pages";
import userRouteMap from "../../../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: userRouteMap.LOGINREGISTRATION.path,
      name: "LoginRegistration",
      key: userRouteMap.LOGINREGISTRATION.path,
      private: true,
      belongsToSidebar: true,
      element: <LoginRegistration />,
    },
  ];
}
