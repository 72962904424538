import { PackageSelectionOption } from "../../../pages";
import userRouteMap from "../../../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: userRouteMap.PACKAGESELECTIONOPTION.path,
      name: "PackageSelectionOption",
      key: userRouteMap.PACKAGESELECTIONOPTION.path,
      private: true,
      belongsToSidebar: true,
      element: <PackageSelectionOption />,
    },
    {
      path: `${userRouteMap.PACKAGESELECTIONOPTION.path}/:id`,
      name: "PackageSelectionOption",
      key: `${userRouteMap.PACKAGESELECTIONOPTION.path}/:id`,
      private: true,
      belongsToSidebar: true,
      element: <PackageSelectionOption />,
    },
  ];
}
