import "/node_modules/bootstrap/dist/js/bootstrap.js";
import "/node_modules/bootstrap/dist/css/bootstrap.css";
import "./App.css";
import { useRoutes } from "react-router-dom";
import { routes } from "./route";
import "/node_modules/bootstrap/dist/js/bootstrap.js";
import "/node_modules/bootstrap/dist/css/bootstrap.css";
import "./App.css";
import { Suspense } from "react";
// import { Spin } from "antd";
import { Grid } from "react-loader-spinner";

function App() {
  window.scrollTo({ top: 0, behavior: "smooth" });
  function RouteLayout({ path }) {
    const element = useRoutes(path);
    return element;
  }
  return (
    <>
      <Suspense
        fallback={
          <div className="loaderBox">
            {/* <Spin /> */}
            <Grid
              visible={true}
              height="80"
              width="80"
              color="#F7CC42"
              ariaLabel="grid-loading"
              radius="12.5"
              wrapperStyle={{}}
              wrapperClass="grid-wrapper"
            />
          </div>
        }
      >
        <RouteLayout path={routes()} />
      </Suspense>
    </>
  );
}

export default App;
