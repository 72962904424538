import homepage from "./HomePage";
import flightListing from "./FlightListing";
import flightReturn from "./FlightListingReturn";
import hotelCabs from "./HotelAndCabs";
import packages from "./Packages";
import packagesSelectionOption from "./PackageSelectionOption";
import payment from "./Payment";
import loginRegistration from "./LoginRegistration";
import bookingAndReview from "./Review/index";
import account from "./Account/index";

const AccessControl = {
  ...homepage,
  ...flightListing,
  ...flightReturn,
  ...hotelCabs,
  ...packages,
  ...packagesSelectionOption,
  ...payment,
  ...loginRegistration,
  ...bookingAndReview,
  ...account,
};

export default AccessControl;
