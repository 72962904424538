import { Packages } from "../../../pages";
import userRouteMap from "../../../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: userRouteMap.PACKAGE.path,
      name: "Package",
      key: userRouteMap.PACKAGE.path,
      private: true,
      belongsToSidebar: true,
      element: <Packages />,
    },
    {
      path: `${userRouteMap.PACKAGE.path}/:id`,
      name: "Package",
      key: userRouteMap.PACKAGE.path,
      private: true,
      belongsToSidebar: true,
      element: <Packages />,
    },
  ];
}
