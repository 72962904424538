import { HomePage } from "../../pages";
import userRouteMap from "../../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: userRouteMap.HOMEPAGE.path,
      name: "HomePage",
      key: userRouteMap.HOMEPAGE.path,
      private: false,
      belongsToSidebar: false,
      element: <HomePage />,
    },
  ];
}
