import toastr from "toastr";

const modalNotification = ({ type, message }) => {
  // let icon = {
  //   info: "ni ni-info-fill",
  //   success: "ni ni-check-circle-fill",
  //   error: "ni ni-cross-circle-fill",
  //   warning: "ni ni-alert-fill",
  // };

  let msg = `${message}`;
  toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: false,
    positionClass: "toast-top-right",
    closeHtml: '<span className="btn-trigger">&#10006;</span>',
    preventDuplicates: true,
    showDuration: "1500",
    hideDuration: "1500",
    timeOut: "1500",
    toastClass: "toastr",
    extendedTimeOut: "5000",
  };
  // eslint-disable-next-line
  // console.log("message", toastr[type](msg));
  toastr[type](msg);
};

export default modalNotification;
